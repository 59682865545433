export type IThemeSettingsOptions = "dark" | "light";

export type ITheme = "dark" | "light";

const availableThemes: {
  key: IThemeSettingsOptions;
  text: string;
}[] = [
  { key: "light", text: "components.theme.light" },
  { key: "dark", text: "components.theme.dark" },
];

export const useTheme = () => {
  // composable
  const themeUserSettings = useCookie<IThemeSettingsOptions>("theme");

  // methods
  const getUserSettings = (): IThemeSettingsOptions =>
    themeUserSettings.value === "dark" ? "dark" : "light";
  // restrict theme to dark or light only, to avoid any crash because of invalid theme settings in past

  const getRealtimeTheme = (): ITheme => {
    const now = new Date();
    const hour = now.getHours();
    const isNight = hour >= 17 || hour <= 5;
    return isNight ? "dark" : "light";
  };

  // state
  const themeSettings = useState<IThemeSettingsOptions>(
    "theme.settings",
    () => getUserSettings() ?? getRealtimeTheme(),
  );

  const currentTheme = useState<ITheme>("theme.current", () =>
    process.client ? getUserSettings() : getRealtimeTheme(),
  );

  // watchers
  watch(themeSettings, (val) => onThemeSettingsChange(val));
  const onThemeSettingsChange = (themeSettings: IThemeSettingsOptions) => {
    currentTheme.value = themeSettings;
    themeUserSettings.value = themeSettings;
  };

  // init theme
  const init = () => {
    themeSettings.value = getUserSettings();
  };
  onThemeSettingsChange(themeSettings.value);

  // lifecycle
  // tryOnBeforeMount(() => init());
  onBeforeMount(() => init());

  return {
    themeSettings,
    currentTheme: computed(
      () => availableThemes.filter((x) => x.key === currentTheme.value)[0],
    ),
    availableThemes,
    getUserSettings,
  };
};
