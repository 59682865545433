<script lang="ts" setup>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { useModal } from "../stores/modal";
import { ModalId } from "~/stores/entites";
const { t, currentLocale } = useLang();
const isDarkTheme = useTheme().currentTheme.value.text === "dark";
const modal = useModal();
const props = defineProps({
  modalId: {
    type: String as PropType<ModalId>,
    default: "",
  },
  status: {
    type: String,
    default: "error", // error, success, info, warning
  },
  title: {
    type: String,
    default: "Are you sure?",
  },
  description: {
    type: String,
    default: "",
  },
  submitTitle: {
    type: String,
    default: "Proceed",
  },
  cancelTitle: {
    type: String,
    default: null,
  },
  toolTip: {
    type: String,
    default: "",
  },
  sensitive: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  force: {
    type: Boolean,
    default: false,
  },
});

defineEmits(["submit-action", "cancel-action"]);
const { open } = storeToRefs(modal);
</script>
<template>
  <TransitionRoot
    as="template"
    :show="open && props.modalId === modal.$state.modalId"
  >
    <Dialog
      as="div"
      class="relative"
      :class="{
        dark: isDarkTheme,
        'z-10': !force,
        'z-50': force,
      }"
      :dir="currentLocale.dir"
      @close="modal.toggle(props.modalId)"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-900 bg-opacity-75 dark:bg-opacity-75 dark:bg-gray-700"
        />
      </TransitionChild>

      <div
        class="fixed inset-0 overflow-y-auto"
        :class="{
          'z-10': !force,
          'z-50': force,
        }"
      >
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden bg-white dark:bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div class="absolute top-0 right-0 rtl:left-0 pt-4 pr-4 rtl:pl-4">
                <button
                  type="button"
                  class="text-gray-400 hover:text-gray-500"
                  @click="modal.toggle(props.modalId)"
                >
                  <span class="sr-only">Close</span>
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div>
                <div
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full"
                  :class="{
                    'bg-red-100': status === 'error',
                    'bg-green-100': status === 'success',
                    'bg-amber-100': status === 'warning',
                  }"
                >
                  <Icon
                    v-if="status === 'error' || status === 'warning'"
                    name="heroicons:exclamation-triangle"
                    class="h-6 w-6"
                    :class="
                      status === 'warning' ? 'text-amber-600' : 'text-red-600'
                    "
                    aria-hidden="true"
                  />
                  <Icon
                    v-else
                    name="heroicons:check"
                    class="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    class="text-lg font-medium leading-6"
                    :class="{ sensitive }"
                    >{{ title }}
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500" :class="{ sensitive }">
                      {{ description }}
                    </p>

                    <slot name="form"></slot>
                  </div>
                </div>
              </div>
              <div
                class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"
              >
                <Button
                  tabindex="0"
                  :loading="loading"
                  :text="submitTitle"
                  class="inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm sm:col-start-2 sm:text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  :class="{
                    'focus:ring-primary-500 bg-primary-500 hover:bg-primary-600':
                      status === 'success',
                    'focus:ring-red-500 bg-red-600 hover:bg-red-700':
                      status === 'error',
                    'focus:ring-primary-500 bg-primary-500 hover:bg-primary-700':
                      status === 'warning',
                  }"
                  @click="$emit('submit-action')"
                >
                </Button>
                <button
                  type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:col-start-1 sm:mt-0 sm:text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  @click="
                    $emit('cancel-action');
                    modal.toggle(props.modalId);
                  "
                >
                  {{ cancelTitle ?? t("actions.cancel") }}
                </button>
              </div>
              <div class="mt-4 rtl:text-right">
                <slot name="footer"> </slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
